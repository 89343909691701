/* MAIN NAVIGATION STYLES */
.nav-backdrop {
    position: absolute;
    right: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    cursor: pointer;
    z-index: -80;
    @include opacity(0.2);
    @include transition(opacity 0.3s linear);

    &.show {
        top: 0;
        z-index: 1100;
        @include opacity(0);
    }
}

.main-navigation-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
    cursor: pointer;


    &.open {
        right: 0;
        width: 100%;
    }
}


.main-navigation {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1110 !important;
    max-height: 100vh;
    @include gradient-two-clr-linear(#0a141e, #000);
    overflow-y: auto;
    width: 350px;
    @include translate(100%, 0);
    @include transition(all 0.3s ease-out);
    // firefox scrollbar
    overflow-y: scroll;
    scrollbar-color: rgba(color('primary'), 0.7) transparent;

    //internet explorer scrollbar
    scrollbar-face-color: color('primary');
    scrollbar-arrow-color: color('primary');
    scrollbar-track-color: transparent;
    scrollbar-shadow-color: rgb(0, 0, 0);
    scrollbar-highlight-color: rgb(0, 0, 0);
    scrollbar-3dlight-color: color('primary');
    scrollbar-darkshadow-Color: transparent;

    // webkit browsers (chrome...) scrollbar
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(146, 192, 31, 0.3);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(color('primary'), 0.7);
    }

    .nav-list {
        font-size: 0.95rem;

        .nav-item {
            padding: 20px 25px;
            font-size: 0.95rem;
            border-top: 0.5px solid #323232;
            text-transform: uppercase;
            width: 100%;
            @include transition(all 0.2s ease-in-out);

            a {
                color: color('white');
                display: inline-block !important;
                text-decoration: none !important;

                &.nav-link {
                    max-width: 80%;
                    min-width: 20%;
                    padding: 0;
                }

                &.nav_dropdown_toggler {
                    width: 100%;
                    min-width: 20px;
                    padding: 7px 0;
                }

                @media (min-width: 992px) {
                &:hover,
                &:active {
                    color: color('primary');
                }
            }
            }

            &:last-of-type {
                border-bottom: 0.5px solid #323232;
            }
        }

        .dropdown-menu {
            position: static !important;
            float: none;
            transform: translate3d(0px, 0px, 0px) !important;
            background-color: transparent;
            @include transition(all .3s ease-out);

            .dropdown-item {
                padding: 0.5rem 1.5rem;
            }
        }

        .dropdown-list {
            background: transparent;

            li {
                font-size: 0.95rem;
                text-transform: initial;
                padding: 3px 0;
                text-transform: uppercase;

                a {
                    margin-left: 15px;
                    color: color('white');
                    white-space: normal;
                    // hyphens: auto;
                    // -ms-hyphens: auto;

                    &:hover {
                        background-color: transparent;
                    }

                    &:focus {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &.show {
        @include translate(0, 0);
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
    }

    &.light-theme {
        background-color: #ffffff;

        .nav-list {
            .nav-list-item {
                border-color: rgba(0, 0, 0, 0.1);
                @include opacity(0.8);

                a {
                    color: color('text', 'dark');

                    &:hover,
                    &:active {
                        color: darken(color('text', 'dark'), 10%);
                    }
                }
            }
        }

        .btn-close {
            color: color('text', 'dark');
        }
    }
}

/* MEDIA QUERIES */
@media (min-width: 1024px) {

    /* SCROLLBAR STYLE */
    .main-navigation {
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(146, 192, 31, 0.3);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(color('primary'), 0.7);
        }

        &.light-theme {
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

@media (max-width: $media_sm) {
    .main-navigation {
        .nav-list {
            .nav-list-item {
                font-size: 1.7rem;
            }

            .dropdown-list {
                li {

                    // font-size: 1.5rem;
                    a {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .main-navigation {
        width: 85%;
    }
}